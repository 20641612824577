import React from "react";
import { BsInstagram, BsLinkedin, BsVimeo, BsYoutube } from "react-icons/bs";

export default function ContactDrawer({ isOpen, onClose }) {
  return (
    <div
      className={`fixed h-full  z-[104]  w-full md:w-[50%] lg:w-[25%] inset-y-0 right-0 bg-black text-white transition-transform transform ${
        isOpen ? "translate-x-0" : "translate-x-full"
      } overflow-y-auto grid grid-cols-1 gap-4 px-4 `}
    >
      <div className="p-8  justify-center relative   h-full flex flex-col">
        <button
          className="absolute block lg:hidden hover:text-red-500 top-5 right-5 text-3xl"
          onClick={onClose}
        >
          &times;
        </button>
        {/* <h2 className="text-4xl mb-4">Contact</h2> */}
        {/* <div className="border-b w-8 border-red-500"></div> */}
        <div className="space-y-4 text-center  my-16">
          <p className=" mb-2">
            If you’d like to discuss a potential project or collaboration,
            please email{" "}
            <a
              href="mailto:contact@moonshotmedia.in"
              className="underline text-red-500 hover:text-white hover:no-underline"
            >
              contact@moonshotmedia.in
            </a>
          </p>
        </div>

        
        <div className="   flex items-center justify-center gap-8 text-3xl">
          <a className="hover:scale-110 duration-300 hover:text-red-500" href="https://www.instagram.com/moonshot.fun/">
            <BsInstagram />
          </a>
          <a className="hover:scale-110 duration-300 hover:text-red-500" href="https://www.linkedin.com/company/moonshot.fun/">
            <BsLinkedin />
          </a>
          <a className="hover:scale-110 duration-300 hover:text-red-500" href="https://www.youtube.com/@MoonshotAdvertising">
            <BsYoutube />
          </a>
          <a className="hover:scale-110 duration-300 hover:text-red-500" href="https://vimeo.com/moonshotfun">
            <BsVimeo />
          </a>
        </div>
      </div>
    </div>
  );
}
