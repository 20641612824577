import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Nav from "./components/nav";
import logo from "./assets/favicon.png";
import { fetchProjectsData } from "./components/dataService";
import WelcomeVideo from "./components/welcome";
import HomePage from "./home";
import Work from "./work";
import Footer from "./components/footer";

const App = () => {
  const [projects, setProjects] = useState([]);
  const [logoSrc, setLogoSrc] = useState(logo);
  const [logoSize, setLogoSize] = useState("large");
  const [showVideo, setShowVideo] = useState(true);

  useEffect(() => {
    handleLogoChange(logo, logoSize);

    const loadProjectsData = async () => {
      const projectsData = await fetchProjectsData();
      setProjects(projectsData);
    };

    loadProjectsData();
    return () => {
      // Optionally clear cache here if needed
      // projectsCache = null;
    };
  }, []);




  const handleLogoChange = (logo, size) => {
    setLogoSrc(logo);
    setLogoSize(size);
  };

  const handleVideoEnd = () => {
    setShowVideo(false);
  };

  return (
    <Router>
      <div className="bg-black h-full font-lato min-h-screen w-screen">
        {showVideo && <WelcomeVideo onVideoEnd={handleVideoEnd} />}

        <header>
          <link
            href="https://cdn.jsdelivr.net/npm/fullpage.js@4.0.25/dist/fullpage.min.css"
            rel="stylesheet"
          />
        </header>
        <Nav logoSrc={logoSrc} logoSize={logoSize} />
        <Routes>
          <Route
            path="/"
            element={
              <HomePage projects={projects} onLogoChange={handleLogoChange} />
            }
          />
          <Route
            path="/work"
            element={
              <Work projects={projects} onLogoChange={handleLogoChange} />
            }
          />
        </Routes>

      </div>
    </Router>
  );
};

export default App;
