import AnimatedText from "./anitext";
import FlipLinkSS from "./fliptextstagger";
import useMobile from "../hook/useMobile";

const Player = ({ teaser, title, brand }) => {
  const isMobile = useMobile();

  return (
    <div className="relative h-full lg:h-screen md:opacity-100 top-0  w-full">
      <div className="flex w-full  h-full">
        <div className="relative w-full h-full overflow-hidden">
          <div className="w-full  h-full">
            <video
              controls={false}
              className="h-full w-full object-cover  rounded-sm"
              disablePictureInPicture
              preload="auto"
              autoPlay
              muted
              loop
              playsInline
            >
              <source src={teaser} type="video/mp4" />
              Your browser does not support the video tag.
            </video>{" "}
          </div>
        </div>
      </div>
      {title === "Reel" && (
        <div className="absolute  bottom-8 flex gap-4 items-center px-2 md:px-8 w-full">
          <div className="z-10   font-light  md:text-7xl lg:text-9xl text-3xl tracking-wide text-white">
            <h1>
              <FlipLinkSS>Welcome&nbsp;to</FlipLinkSS>
            </h1>
            <h1 className="font-bold  uppercase">
              <FlipLinkSS>MoonShot</FlipLinkSS>
            </h1>
          </div>
        </div>
      )}
      {title !== "Reel" && title !== "ReelMobile" && (
        <div className="absolute text-center inset-0 flex items-center justify-center cursor-pointer">
          <div className="z-10 w-[80%] mx-auto text-center text-white">
            <AnimatedText brand={brand} title={title} smallText={isMobile} />
          </div>
        </div>
      )}
    </div>
  );
};

export default Player;
