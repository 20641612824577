import React, { useEffect, useMemo, useState } from "react";
import Player from "./components/player";
import FullscreenModal from "./components/fullscreen";
import { motion } from "framer-motion";
import ScrollToTop from "react-scroll-to-top";
import { FaChevronUp } from "react-icons/fa";
import { TfiArrowUp } from "react-icons/tfi";
import { useNavigate } from "react-router-dom";
import logoScrolled from "./assets/logo2.png";
import logo from "./assets/favicon.png";
import FlipLinkSS from "./components/fliptextstagger";
import useMobile from "./hook/useMobile";
import Footer from "./components/footer";

function HomePage({ projects, onLogoChange }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const isMobile = useMobile();
  const navigate = useNavigate();
  const [showExploreButton, setShowExploreButton] = useState(true); // New state

  useEffect(() => {
    if (isMobile) {
      // On mobile, always show the scrolled logo
      onLogoChange(logoScrolled, "small");
    } else {
      const handleScroll = () => {
        // On larger screens, change logo based on scroll position
        if (window.scrollY > 50) {
          onLogoChange(logoScrolled, "small");
        } else {
          onLogoChange(logo, "large");
        }
        if (window.scrollY > 10) {
          setShowExploreButton(false); // Hide the button after scrolling down 200px
        } else {
          setShowExploreButton(true); // Show the button when at the top
        }
      };

      window.addEventListener("scroll", handleScroll);

      // Initial logo setup
      handleScroll();

      // Cleanup on unmount
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, [onLogoChange, isMobile]);

  const handleVideoClick = (project) => {
    if (project.id === 1) {
      return;
    }
    setSelectedVideo(project);
    setIsModalOpen(true);
  };

  const handleClick = (project) => {
    handleVideoClick(project);
  };

  const filteredProjects = useMemo(() => {
    let sortedProjects = projects
      .filter((project) => project.id)
      .sort((a, b) => (a.id === 1 ? -1 : b.id === 1 ? 1 : a.id - b.id));

    if (isMobile) {
      sortedProjects = sortedProjects.slice(1, 11);
    }

    return sortedProjects;
  }, [projects, isMobile]);

  const reelMobileProject = useMemo(() => {
    return projects.find((project) => project.title === "ReelMobile");
  }, [projects]);

  return (
    <>
      <div className="h-full lg:pt-0 pt-20 relative bg-black w-full">
        <div className="flex flex-col items-center gap-y-4">
          <div className="relative w-full lg:hidden block">
            <div className="object-cover rounded-lg w-full h-full">
              {reelMobileProject && (
                <video
                  controls={false}
                  autoPlay
                  disablePictureInPicture
                  playsInline
                  preload="auto"
                  loop
                  muted
                  className="h-full w-full"
                >
                  <source src={reelMobileProject.teaser} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              )}
              <div className="absolute bottom-16 flex gap-4 items-center px-2 md:px-8 w-full">
                <div className="z-10 w-[80%] font-light md:text-7xl text-4xl tracking-wide text-white">
                  <h1>
                    <FlipLinkSS>Welcome&nbsp;to</FlipLinkSS>
                  </h1>
                  <h1 className="font-bold uppercase">
                    <FlipLinkSS>MoonShot</FlipLinkSS>
                  </h1>
                </div>
              </div>
            </div>
          </div>

          {filteredProjects &&
            filteredProjects.map((project, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0.5 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 0.3 }}
                onClick={() => handleClick(project)}
                className="w-full cursor-pointer lg:px-0 px-4 bg-black h-full"
              >
                <Player
                  title={project.title ? project.title : ""}
                  description={project.description}
                  brand={project.brand ? project.brand : " "}
                  teaser={project.teaser}
                  vimeoUrl={project.link}
                  logo={project.logo}
                  isActive={true}
                  showControls={true}
                />
              </motion.div>
            ))}
          <ScrollToTop
            smooth
            component={<FaChevronUp className="text-2xl" />}
            className="rounded-full flex items-center justify-center btn-circle hover:scale-105 duration-300"
          />
        </div>
        <div className="absolute bottom-8 left-1/2 transform -translate-x-1/2">
          <button
            onClick={() => navigate("/work")}
            className="group uppercase animate-pulse hover:scale-110 flex transition-all duration-300 items-center gap-2 text-xs tracking-widest bg-transparent font-medium text-white"
          >
            View More
            <TfiArrowUp
              style={{ strokeWidth: "1" }}
              className="rotate-45 group-hover:translate-x-2 transition-all duration-500 group-hover:-translate-y-2"
            />
          </button>
        </div>
        {showExploreButton && (
          <div className="fixed bottom-10 right-16 hidden  lg:block transform">
            <button className="group uppercase animate-pulse flex transition-all duration-300 items-center gap-2 text-xl tracking-widest bg-transparent font-medium text-white">
              Explore More
              <TfiArrowUp style={{ strokeWidth: "1" }} className="rotate-180" />
            </button>
          </div>
        )}

        {isModalOpen && (
          <FullscreenModal
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            videoProps={
              selectedVideo
                ? {
                    url: selectedVideo.link,
                    title: selectedVideo.title,
                    description: selectedVideo.description,
                    isActive: true,
                    showControls: true
                  }
                : {}
            }
          />
        )}
      </div>
      <Footer />
    </>
  );
}

export default HomePage;
