import React from 'react';
import ReactDOM from 'react-dom';
import { AiOutlineClose } from 'react-icons/ai';
import IFramePlayer from '../iframePlayer';
import { motion } from 'framer-motion';

const FullscreenModal = ({ isOpen, onClose, videoProps }) => {
  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <motion.div
      className="fixed inset-0 z-[104] flex items-center justify-center bg-black"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
    >
      <div className="relative w-full h-full flex items-center">
        <button
          onClick={(e) => {
            e.stopPropagation(); // Prevent any click event bubbling
            onClose();
          }}
          className="absolute z-[106] top-4 right-4 text-white text-3xl"
        >
          <AiOutlineClose />
        </button>
        {videoProps.url && <IFramePlayer url={videoProps.url} autoplay={isOpen}/>}
      </div>
    </motion.div>,
    document.body
  );
};

export default FullscreenModal;
