import React, { useEffect, useState } from "react";
import { fetchProjectsData } from "./dataService";
import { useNavigate } from "react-router-dom";

export default function Clients({ isOpen, onClose }) {
  const navigate = useNavigate();
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    const loadProjectsData = async () => {
      try {
        const projectsData = await fetchProjectsData();
        setProjects(projectsData);
      } catch (error) {
        console.error("Error fetching projects data:", error);
      }
    };

    loadProjectsData();
  }, []);

  const handleLogoClick = (brand) => {
    navigate("/work", { state: { brand } });
    onClose();
  };

  const uniqueBrands = new Set();

  return (
    <div
      className={`fixed z-[104] w-full md:w-[50%] lg:w-[32%] inset-y-0 right-0 bg-black text-white transition-transform transform ${
        isOpen ? "translate-x-0" : "translate-x-full"
      } overflow-y-auto grid grid-cols-1 gap-4 px-4`}
    >
      <div className="p-8 w-full mt-16 lg:mt-24 relative">
        <button
          className="absolute lg:hidden block hover:text-red-500 top-5 right-5 text-3xl"
          onClick={onClose}
        >
          &times;
        </button>
        <div className="gap-4  text-center overflow-y-scroll grid md:grid-cols-3 grid-cols-2 my-16">
          {projects
            .filter((e) => {
              if (e && !uniqueBrands.has(e.brand) && e.logo) {
                uniqueBrands.add(e.brand);
                return true;
              }
              return false;
            })
            .map((e, i) => (
              <div
                onClick={() => handleLogoClick(e.brand)}
                key={i}
                className="flex justify-center items-center  w-full  p-4 bg-black hover:-translate-y-2 duration-300 cursor-pointer"
              >
                <img
                  src={e.logo}
                  alt={e.brand}
                  className="max-h-full max-w-full object-contain"
                  loading="lazy"
                />
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}
