// Overlay.js
import React from 'react';

const Overlay = ({ isOpen, onClick }) => {
  return (
    isOpen ? (
      <div
        className="fixed inset-0 bg-black opacity-80 z-[104]"
        onClick={onClick}
      />
    ) : null
  );
};

export default Overlay;
