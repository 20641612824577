import React, { useState,  useEffect } from "react";
import { motion } from "framer-motion";


const WelcomeVideo = ({ onVideoEnd }) => {
  const [isPlaying, setIsPlaying] = useState(true);
  const [zIndex, setZIndex] = useState(107);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsPlaying(false);
      onVideoEnd();
    }, 4000);

    const zIndexTimer = setTimeout(() => {
      setZIndex(99);
    }, 2200);

    return () => {
      clearTimeout(timer);
      clearTimeout(zIndexTimer);
    };
  }, [onVideoEnd]);

  return (
    isPlaying && (
      <motion.div
        className="fixed h-screen w-screen flex items-center justify-center bg-black"
        style={{ zIndex }} 
        initial={{ y: 0 }}
        animate={{ y: "-100%" }}
        transition={{ duration: 0.8, delay: 2.5 }}
        onAnimationComplete={() => setIsPlaying(false)}
      >
        <motion.img
          src='https://cdn.moonshotmedia.in/loading_6aebbb5c96.gif'

          alt="Loading"
          initial={{ opacity: 1 }}
          animate={{ opacity: 0 }}
          transition={{ duration: 0.8, delay: 2 }}
          className="object-contain"
        />
      </motion.div>
    )
  );
};

export default WelcomeVideo;
